import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { useAuthStore } from "../stores/AuthStore";

const Header = (props) => {
  const user = useAuthStore((state) => state.user);
  return (
    <div className="nk-header nk-header-fixed is-dark">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              href="#"
              className="nk-nav-toggle nk-quick-nav-icon"
              onClick={() =>
                props.sidebarRef.current?.classList.toggle("nk-sidebar-active")
              }
            >
              <em className="icon ni ni-menu" />
            </a>
          </div>

          <div className="nk-header-brand d-xl-none">
            <a href="/" className="logo-link">
              {/* <img
                className="logo-light logo-img"
                src="/assets/logo-2.png"
                alt="logo-dark"
              /> */}
            </a>
          </div>

          <div className="nk-header-tools d-flex align-items-center">
            {/* show user balance */}

            <span
              className="text-success mr-4 "
              style={{
                fontSize: "1.5rem",
              }}
            >
              Balance: {"$" + parseFloat(user.balance).toFixed(2)}
            </span>

            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <a
                  href="#"
                  className="dropdown-toggle mr-n1"
                  data-toggle="dropdown"
                >
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt" />
                    </div>
                    <div className="user-info d-none d-xl-block">
                      <div className="user-name dropdown-indicator">
                        {user.username}
                      </div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-info">
                        <span className="lead-text">{user.username}</span>
                        <span className="sub-text">{user.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link to="/settings">
                          <em className="icon ni ni-setting-alt" />
                          <span>Account Setting</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link
                          to="#"
                          onClick={() => {
                            Cookies.remove("token");
                            window.location.reload();
                          }}
                        >
                          <em className="icon ni ni-signout" />
                          <span>Sign out</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* .nk-header-wrap */}
      </div>
      {/* .container-fliud */}
    </div>
  );
};

export default Header;
